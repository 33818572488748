import type { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageHead from "../components/PageHead";
import commandsPic from "../public/home/commands.png";
import pollPic from "../public/home/poll.png";
import spotifyPic from "../public/home/spotify.png";
import allofem from "../public/logos/allofem.png";
import { MainProps } from "./_app";
import { useEffect, useState } from "react";

const Home: NextPage<MainProps> = (props: MainProps) => {
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMessage, setFormMessage] = useState("");

  useEffect(() => {
    const slideInDiv = document.getElementsByClassName("intro-animation");
    for (let i = 0; i < slideInDiv.length; i++) {
      slideInDiv[i].classList.remove("scale-0")
    }
  }, [])

  return (
    <div className="flex-1 h-full w-full flex flex-col scroll-smooth">
      <PageHead link="" />
      <Header changeTheme={props.changeTheme} />

      <div className="min-h-[calc(100vh-68px)] flex flex-shrink-0 bg-base-100 flex-col md:flex-row">
        <div className="flex w-full md:w-1/2 flex-col md:flex-row">
          <div className="flex md:pl-16 w-full md:py-10 justify-center flex-col h-[90vh] md:h-auto">
            <h1 className="font-bold leading-tight text-center md:text-left text-[10vw] md:text-[5vw]">
              A collection of
              <br />
              <span className="bg-gradient-to-r text-transparent bg-clip-text from-indigo-500 via-purple-500 to-pink-500 after:blur-sm">
                fully customizable
              </span>
              <br />
              stream additions
            </h1>
            <div className="flex flex-row">
              <p className="font-light text-gray-400 text-center md:text-left text-[5vw] md:text-[1.5vw]">
                A huge collection of stream utilities, all in one place
              </p>
            </div>
            <div className="flex flex-row mt-5 self-center md:self-start">
              <button className="btn md:btn-lg md:btn-wide mr-4">Learn more</button>
              <Link href="/utils">
                <button className="btn md:btn-lg md:btn-wide btn-neutral hover:scale-105">Get started</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full md:w-1/2 relative">
          <div
            className="relative md:absolute w-4/5 mx-3 my-4 md:mx-0 md:my-0 md:w-auto md:left-0 md:top-[44%] transition-all ease-out delay-200 duration-300 scale-0 intro-animation">
            <Image src={spotifyPic} alt="Spotify overlay" width={450} height={450} />
          </div>
          <div className="relative md:absolute w-4/5 mx-3 my-4 md:mx-0 md:my-0 md:w-auto self-end md:right-6 md:bottom-28 transition-all ease-out delay-300 duration-300 scale-0 intro-animation">
            <Image src={pollPic} alt="Poll overlay" width={400} height={400} />
          </div>
          <div className="relative md:absolute w-4/5 mx-3 my-4 md:mx-0 md:my-0 md:w-auto md:right-20 md:top-44 transition-all ease-out delay-100 duration-300 scale-0 intro-animation">
            <Image src={commandsPic} alt="Chat commands" width={500} height={500} />
          </div>
        </div>
      </div>

      <div className="flex bg-base-100 flex-col-reverse self-center md:flex-row w-full justify-evenly min-h-[90vh]">
          <div className="flex w-4/5 md:w-1/2 self-center visible justify-center flex-col md:py-20 md:px-20">
            <Image
              src={allofem}
              alt="Services"
              width={700}
              height={700}
              className="m-auto hover:scale-105 duration-100"
            />
          </div>
          <div className="flex w-full md:w-1/2 ">
            <div className="flex md:pl-16 w-full md:py-10 justify-center flex-col">
              <h1 className="font-bold leading-tight text-center md:text-left text-[10vw] md:text-[5vw]">
                Integates with
                <br />
                <span className="bg-gradient-to-r text-transparent bg-clip-text from-cyan-600 via-violet-600 to-purple-700">
                  all the things
                </span>
              </h1>
              <div className="flex flex-row">
                <p className="font-light text-gray-400 text-center md:text-left text-[5vw] md:text-[1.5vw]">
                  Integrates with all your favorite platforms and services
                </p>
              </div>
            </div>
        </div>
      </div>

      <div className="flex bg-base-100 flex-col justify-center min-h-[90vh]">
        <div className="flex flex-col md:flex-row">
          <div className="flex w-full md:w-1/2">
            <div className="flex md:pl-16 w-full md:py-10 justify-center flex-col">
              <h1 className="font-bold leading-tight text-center md:text-left text-[10vw] md:text-[5vw]">
                Built for creators and moderators
              </h1>
              <div className="flex flex-row">
                <p className="font-light text-gray-400 text-center md:text-left text-[5vw] md:text-[1.5vw]">
                  Do you have ideas for new utils, or general feedback? Let us know!
                </p>
              </div>
            </div>
          </div>
          <div className="flex md:w-1/2 justify-center items-center flex-col px-4 md:px-20 py-10">
            <div className="flex bg-neutral justify-center w-full py-5 md:py-10 px-4 rounded-3xl">
              <div className="form-control w-full max-w-md">
                <label className="label">
                  <span className="label-text">What is your name?</span>
                </label>
                <input
                  type="text"
                  placeholder="John Johnson"
                  className="input input-bordered"
                  onChange={(event) => setFormName(event.target.value)}
                />
                <label className="label">
                  <span className="label-text">What is your email?</span>
                </label>
                <input
                  type="email"
                  placeholder="j.johnson@pete.com"
                  className="input input-bordered w-full"
                  onChange={(event) => setFormEmail(event.target.value)}
                />
                <label className="label">
                  <span className="label-text">Your message</span>
                </label>
                <textarea
                  className="textarea textarea-bordered h-24"
                  placeholder="Dear diary..."
                  onChange={(event) => setFormMessage(event.target.value)}
                />
                <button className="btn btn-primary mt-6">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex bg-base-100 flex-col justify-center min-h-[70vh] w-full min-w-full">
        <h1 className="font-bold leading-tight text-center text-[10vw] md:text-[5vw]">
          What are you waiting for?
        </h1>
        <p className="font-light text-gray-400 text-center text-[5vw] md:text-[1.5vw]">
          Get started now, and explore all we have to offer
        </p>
        <Link href="/login" className="self-center">
          <button className="btn animate-grow btn-wide btn-secondary mt-12 btn-md self-center">Get started</button>
        </Link>
      </div>

      {/* <div className="flex bg-base-100 flex-col">
        <div className="flex self-center">
          <h1 className="text-[clamp(2rem,6vw,4rem)] font-bold text-center top-6">
            Some more selling points
          </h1>
        </div>
        <div className="flex">
          <div className="flex w-full md:w-1/2 "></div>
          <div className="flex w-1/2 invisible md:visible justify-center flex-col py-20 px-20">
            <div className="collapse collapse-arrow bg-base-200">
              <input type="radio" name="my-accordion-2" defaultChecked={true} />
              <div className="collapse-title text-xl font-medium">Highly customizable</div>
              <div className="collapse-content">
                <p>
                  Make your stream stand out from the crowd by customizing every detail about your overlays or
                  commands. All utils are made to be highly customizable in an intuitive way.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-base-200">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">Easy to use</div>
              <div className="collapse-content">
                <p>
                  It's no fun having to spend ages trying to understand how to use a piece of software, so all
                  utils are simple to use with instructions on the steps to follow.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow bg-base-200">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">All in one place</div>
              <div className="collapse-content">
                <p>
                  No need to use 20 different services for your stream, STREAM-UTILS combines it all in one
                  place. Is there something that you think STREAM-UTILS should have? Let me know!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </div>
  );
};

export default Home;
