import { signIn, signOut } from "next-auth/react";
import Link from "next/link";
import { useUser } from "./fetchers/useUser";

const pages = {
  1: ["Home", "/"],
  2: ["Pricing", "/pricing"],
  3: ["Utils", "/utils"],
};
const settings = {
  1: ["Account", "/account"],
};

type HeaderProps = {
  changeTheme: () => void;
  isUtils?: boolean;
};

const Header = ({ changeTheme, isUtils }: HeaderProps) => {
  const { user } = useUser();

  return (
    <div className="navbar bg-base-100 md:px-5" id="main-header">
      <div className="dropdown">
        {isUtils && (
          <label htmlFor="util-drawer" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </label>
        )}
        {!isUtils && (
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </label>
        )}
        <ul
          tabIndex={0}
          className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-200 rounded-box w-52">
          {Object.values(pages).map((page) => (
            <li key={page[0]}>
              <Link key={page[0]} href={page[1]}>
                {page[0]}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex-1 navbar-start hidden lg:flex">
        <Link className="btn btn-ghost normal-case text-xl" href="/">
          STREAM-UTILS
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex ">
        <ul className="menu menu-horizontal px-1">
          {Object.values(pages).map((page) => (
            <li key={page[0]}>
              <Link key={page[0]} href={page[1]}>
                {page[0]}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {user?.name && (
        <div className="flex-1 gap-2 navbar-end">
          <a className="text-xl">{user.name!}</a>
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src={user.image!} />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-200 rounded-box w-52">
              {Object.values(settings).map((page) => (
                <li key={page[0]}>
                  <Link key={page[0]} href={page[1]}>
                    {page[0]}
                  </Link>
                </li>
              ))}
              <li>
                <a onClick={() => signOut()}>Logout</a>
              </li>
            </ul>
          </div>
        </div>
      )}
      {!user?.name && (
        <div className="flex-1 gap-2 navbar-end">
          <a className="btn btn-ghost" onClick={() => signIn()}>
            Log in
          </a>
        </div>
      )}
    </div>
  );
};

export default Header;
