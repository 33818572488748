import Head from "next/head";
import { webServer } from "./Server";

type PageHeadProps = {
  page?: string;
  link: string;
};

const PageHead = ({ page, link }: PageHeadProps) => {
  return (
    <Head>
      <title>{page ? `${page.toUpperCase()} | STREAM-UTILS` : "STREAM-UTILS"}</title>
      <meta name="og:title" property="og:title" content={page ? `${page} | STREAM-UTILS` : "STREAM-UTILS"} />
      <meta name="description" content="A collection of utilities for streamers" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href={webServer + link} />
    </Head>
  );
};

export default PageHead;
